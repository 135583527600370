import { useTranslation } from 'react-i18next'
import style from './Page.module.css'
import image from './../images/ceasefire-3-man.jpg'

import infographics1 from './../images/infographics-1.svg'
import infographics2 from './../images/infographics-2.svg'
import infographics3 from './../images/infographics-3.svg'
import infographics4 from './../images/infographics-4.svg'


export default props => {
	const { i18n } = useTranslation()
	const language = i18n.language

	switch(language) {
	case 'en':
		return <CivilianHarmEnglish />
	case 'he':
		return <CivilianHarmHebrew />
	case 'ar':
		return <CivilianHarmArabic />
	default:
		return <CivilianHarmEnglish />
	}
}

const CivilianHarmEnglish = props => {
	return (
		<div className={style.page}>
			<div className={style.hero}>
				<div className={style.heroimage}><img src={image}/></div>
				<div className={style.heroblur}><img src={image}/></div>
			</div>
			<div className={style.pageInner}>
				<div className={style.imageCaption}></div>
				<div className={style.pageText}>
	

					<h1>Civilian harm and access to justice</h1>
					<p>Between 1988 and 2014, Israel paid approximately USD 94 million in compensation for losses inflicted by its security forces on the Palestinian population in the West Bank and Gaza.  However, since 2004, and progressively since, the right to a remedy has been severely constrained through a number of factors including: broadening the definition of the ‘combatant activities exception’ in the law of tort; the designation of Gaza as enemy territory and its residents as citizens of an enemy state; and the combined effect of procedural obstacles, high court fees and securities, and denial of entry permits limiting access to court. As a result, while Jewish Israeli citizens who are residents of the Occupied Palestinian Territories are able to successfully claim compensation for losses they sustain from the operations of Israel’s security forces, the corresponding right to compensation of non-Jewish residents is almost purely theoretical.</p>
					<div className={style.infographic}>
						<img src={infographics1}/>
					</div>
					<p>First, the scope of Israel’s immunity from tort liability expanded. In common with practice in some other jurisdictions, Israeli law grants Israel immunity from claims arising as a result of the combatant activities of its armed forces. Israel amended the definition of this combatant activities exception to the tortious liability of the State to include not only combat activities in their narrow sense, but also policing activities and other activities conducted by the Civil Administration that can support combat or security goals. The effects of the expansion of the combatant activities exception are clear. Until the Second Intifada in late 2000, courts found Israel to be immune from liability in only 30 per cent of the cases in which a tort claim was brought against it for the losses it inflicted on civilians in the Occupied Territories. This number grew significantly following the Second Intifada to the extent that since the latest legislative amendment in 2012 in 87 per cent of cases Israel was held to be immune from liability.</p>
					<div className={style.infographic}>
						<img src={infographics2}/>
					</div>
					<p>Second, in recent years plaintiffs are required to overcome a variety of procedural hurdles in order to launch a claim. Plaintiffs must submit notice on their intent to file a claim within 60 days of the loss they incurred, and the claim itself must be filed within two years (compared to the typical seven year limit). Non-Israeli citizens who are subjects of enemy states or territories are barred from filing tort claims against Israel, rendering plaintiffs from the Gaza Strip without a right to a remedy. Steep litigation costs—including securities and fees—are also a barrier to litigation that deters potential plaintiffs from filing claims, or pursuing claims they have already filed. Additionally, Israel made it very challenging for Palestinian plaintiffs and their witnesses to obtain entry permits to testify. Consequently, most tort claims of Palestinians against Israel are either denied or not pursued for lack of resources.</p>
					
					<p>In contrast, Israel has repeatedly expanded the scope of liability to which the Palestinian Authority is exposed, by allowing tort claims to be brought against the Palestinian Authority for losses inflicted on Jewish citizens during hostilities, even when the activities were conducted by other Palestinian organizations and independent individuals.</p>
					<div className={style.infographic}>
						<img src={infographics3}/>
					</div>
					<div className={style.infographic}>
						<img src={infographics4}/>
					</div>
					
				</div>
				<div className={style.imageCaption}>© Dominika Zara/Shutterstock</div>
			</div>
		</div>
	)
}

const CivilianHarmHebrew = props => {
	return (
		<div className={style.page}>
			<div className={style.hero}>
				<div className={style.heroimage}><img src={image}/></div>
				<div className={style.heroblur}><img src={image}/></div>
			</div>
			<div className={style.pageInner}>
				<div className={style.imageCaption}></div>
				<div className={style.pageText}>
					<h1>נזק לאזרחים וגישה לערכאות</h1>
					<p>בין השנים 2004-1988 ישראל שילמה קרוב ל-94 מיליון דולר (אמריקאי) בפיצויים עבור נזקים שכוחותיה גרמו לאוכלוסייה הפלסטינית בגדה המערבית ורצועת עזה. עם זאת, החל משנת 2004 הזכות לקבלת פיצויים הוגבלה בצורה עקבית ומשמעותית באמצעות מספר דרכים, ובכלל זאת הרחבת חריג הפעולה המלחמתית לאחריות המדינה בנזיקין, הגדרת רצועת עזה כמדינת אויב ותושביה כאזרחי מדינת אויב, והצבת חסמים דיוניים כגון סכומי ערובה וביטחון גבוהים ומניעת היתרי כניסה לישראל לעדים. כתוצאה מכך, תושבי השטחים שהם ישראלים ויהודים יכולים להגיש תביעות נזיקין כנגד ישראל בעוד שהזכות של תושבים לא יהודיים כמעט תיאורטית.</p>
					<div className={style.infographic}>
						<img src={infographics1}/>
					</div>
					
					<p>ראשית, בעוד שחסינות המדינה מתביעות נזיקין בשל פעולות מלחמתיות קיימת במספר מדינות אחרות, חסינותה של ישראל הורחבה באמצעות מספר תיקוני חקיקה כך שהיא חלה לא רק על פעולות מלחמתיות במובנן הצר, אלא גם עבור פעולות שיטור ופעולות של מתאם הפעילות בשטחים שעשויות לתמוך בפעולות מלחמתיות או פעולות למניעת טרור. ההשלכות של הרחבות אלו ברורות. עד האינתיפאדה השנייה, בתי המשפט מצאו שחריג הפעולה המלחמתית חל רק בכ-30 אחוזים מהמקרים שתביעת נזיקין הוגשה כנגד המדינה. אחוז זה עלה בהתמדה מאז האינתיפאדה השנייה, ולאחר התיקון החקיקתי האחרון בשנת 2012 מספר המקרים בהם בתי המשפט מצאו שחריג הפעולה המלחמתית חל והמדינה חסינה מאחריות נזיקית עלה ל-87 אחוזים.</p>
					<div className={style.infographic}>
						<img src={infographics2}/>
					</div>
					<p>שנית, בשנים האחרונות תובעים נדרשים לצלוח שורה של מכשולים דיוניים בכדי לנהל תביעה אזרחית. הם חייבים להגיש הודעה על כוונתם להגיש תביעה תוך 60 ימים ממועד הנזק, והתביעה צריכה להיות מוגשת בתוך שנתיים (בניגוד לשבע שנים הנדרשות בתביעות נזיקין רגילות). אזרחים זרים שהינם נתיני אויב אינם רשאים להגיש תביעה נגד ישראל כלל, מה שמונע מתושבי רצועת עזה מלהגיש תביעות נזיקין. עלויות התביעה, ובכלל זה סכומי אגרות, ערובות, ובטחונות גבוהים, מהווים חסם המרתיע תובעים בכוח ובפועל. בנוסף, ישראל מקשה על תובעים פלסטינים ועדיהם לקבל היתרי כניסה לישראל לעדות. כפועל יוצא, רוב התביעות של פלסטינים כנגד ישראל נדחות או לא מוגשות מלכתחילה.</p>
					<p>מנגד, ישראל הרחיבה באופן שיטתי את אחריות הרשות הפלסטינית באמצעות הכרה באפשרות להטיל אחריות עליה בגין פציעה של אזרחים ישראליים יהודים במהלך פעילויות מזוינות, גם כשאלו ננקטו על ידי ארגונים פלסטיניים אחרים או יחידים.</p>
					<div className={style.infographic}>
						<img src={infographics3}/>
					</div>
					<div className={style.infographic}>
						<img src={infographics4}/>
					</div>
				</div>
				<div className={style.imageCaption}>© Dominika Zara/Shutterstock</div>
			</div>
		</div>
	)
}

const CivilianHarmArabic = props => {
	return (
		<div className={style.page}>
			<div className={style.hero}>
				<div className={style.heroimage}><img src={image}/></div>
				<div className={style.heroblur}><img src={image}/></div>
			</div>
			<div className={style.pageInner}>
				<div className={style.imageCaption}></div>
				<div className={style.pageText}>
					<h1>الأضرار التي تلحق بالمدنيين وسبل الوصول للعدالة</h1>
					<p>في الفترة بين عامي 1988 و2014، دفعت إسرائيل ما يقرب من 94 مليون دولار أمريكي في صورة تعويضات عن الخسائر والأضرار التي ألحقتها قوات الأمن الخاصة بها بالسكان الفلسطينيين في الضفة الغربية وغزة.  ولكن، منذ العام 2004، وبصفة متزايدة من وقتها، قد تم تقييد الحق في الانتصاف تقييدًا شديدًا عبر عدد من العوامل التي تتضمن: توسيع نطاق تعريف "استثناء الأنشطة القتالية" في قانون المسؤولية التقصيرية، وتعيين غزة بوصفها أراضي معادية وتعيين سكانها بوصفهم مواطني دولة عدو، والتأثير المشترك للعوائق الإجرائية ورسوم المحاكم وضماناتها الباهظة ومنع إصدار تصاريح الدخول مما يحد من الوصول إلى المحاكم. ونتيجة لهذا، بينما المواطنون الإسرائيليون اليهود ممن يسكنون الأراضي الفلسطينية المحتلة قادرون على رفع دعاوى قضائية بنجاح للمطالبة بتعويضات عن الأضرار التي لحقت بهم جراء العمليات التي تقوم بها قوات الأمن الإسرائيلية، فإن نفس الحق في التعويضات للسكان من غير اليهود يكاد يكون متوفرًا من الناحية النظرية فقط.</p>
					<div className={style.infographic}>
						<img src={infographics1}/>
					</div>
					<p>أولًا، اتسع نطاق حصانة إسرائيل من المسؤولية التقصيرية. وعلى غرار الممارسات المعمول بها في بعض الولايات القضائية الأخرى، يمنح القانون الإسرائيلي إسرائيل حصانة من الدعاوى القضائية الناتجة عن الأنشطة القتالية التي تقوم بها قواتها المسلحة. وأدخلت إسرائيل تعديلات على تعريف هذا الاستثناء للدولة من المسؤولية التقصيرية فيما يتعلق بالأنشطة القتالية ليشمل ليس فقط الأنشطة القتالية بمعناها الضيق، ولكن ليشمل أيضًا أنشطة الشرطة والأنشطة الأخرى التي تقوم بها الإدارة المدنية والتي يمكن أن تدعم الأهداف المرجوة من القتال أو الأهداف الأمنية. وآثار توسيع نطاق سريان استثناء الأنشطة القتالية واضحة. حتى الانتفاضة الثانية في أواخر عام 2000، قررت المحاكم أن إسرائيل محصنة من المسؤولية في 30 بالمئة فقط من الدعاوى القضائية التي تم رفعها ضدها للمطالبة بتعويضات بموجب المسؤولية التقصيرية عن الأضرار التي ألحقتها إسرائيل بالمدنيين في المناطق المحتلة. ازداد هذا الرقم بصورة كبيرة عقب الانتفاضة الثانية بحيث أنه منذ آخر تعديل قضائي في عام 2012 تم الحكم بأن إسرائيل محصنة من المسؤولية في 87 بالمئة من الدعاوى القضائية.</p>
					<div className={style.infographic}>
						<img src={infographics2}/>
					</div>
					<p>ثانيًا، في السنوات الأخيرة أصبح المدعون مطالبين بالتغلب على مجموعة متنوعة من المعوقات كي يتمكنوا من إقامة دعوى قضائية للمطالبة بتعويضات. على المدعين أن يقدموا إشعار بنيتهم إقامة دعوى قضائية خلال 60 يوم من تاريخ وقوع الضرر ويلزم إقامة الدعوى القضائية نفسها خلال عامين (بالمقارنة مع المهلة المعتادة المقدرة بسبع سنوات). والمواطنون غير الإسرائيليون من مواطني الدول أو المناطق المعادية ممنوعون من إقامة دعاوى قضائية لمطالبة إسرائيل بتعويضات عن مسؤوليتها التقصيرية، مما يحرم المدعين من قطاع غزة من حقهم في الوصول لسبل الانتصاف. تكاليف تقاضي مرتفعة—بما في ذلك الضمانات والرسوم—والتي تعد كذلك عائقًا أمام التقاضي مما يثني المدعين المحتملين عن رفع الدعاوى القضائية أو متابعة ما رفعوه منها بالفعل. بالإضافة لهذا، جعلت إسرائيل من الصعب جدًا على المدعين الفلسطينيين والشهود المؤيدين لدعاواهم الحصول على تصاريح دخول للإدلاء بشهاداتهم. وبالتالي، فمعظم الدعاوى القضائية التي يرفعها الفلسطينيون ضد إسرائيل للمطالبة بتعويضات بموجب المسؤولية التقصيرية إما يتم رفضها أو التوقف عن متابعتها بسبب نقص الموارد.</p>
					<p>وعلى النقيض من ذلك، فقد وسَّعت إسرائيل مرة تلو أخرى نطاق المسؤولية التي تتحملها السلطة الفلسطينية عن طريق السماح برفع الدعاوى القضائية للمطالبة بتعويضات بموجب المسؤولية التقصيرية ضد السلطة الفلسطينية عن الأضرار والخسائر التي تلحق بالمواطنين اليهود أثناء أعمال العنف حتى حين تكون هذه الأعمال قد ارتكبتها منظمات فلسطينية أخرى وأفراد مستقلون.</p>
					<div className={style.infographic}>
						<img src={infographics3}/>
					</div>
					<div className={style.infographic}>
						<img src={infographics4}/>
					</div>
				</div>
				<div className={style.imageCaption}>© Dominika Zara/Shutterstock</div>
			</div>
		</div>
	)
}






